<template>
  <div class="cont">
    <div class="register__header">
      <h1 class="register__title">Дилеры и сервисы</h1>
      <div class="register__text"></div>
    </div>
    <div class="sect_subscribe">
      <form action="#" class="form">
        <div class="line_flex flex">
          <div class="line_form">
            <div class="select_wrap">
              <v-select @change="regionAndTypeReset"
                  v-model="country"
                  :items="filters"
                  item-text="NAME"
                  item-value="ID"
                  label="Страна"
                  name="country"
              ></v-select>
            </div>
          </div>
          <div class="line_form" v-if="country">
            <div class="select_wrap">
              <v-select @change="typeReset"
                  v-model="region"
                  :items="regions"
                  item-text="NAME"
                  item-value="ID"
                  label="Регион/Область"
                  name="region"
              ></v-select>
            </div>
          </div>
          <div class="line_form" v-if="region">
            <div class="select_wrap">
              <v-select @change="onSubmit"
                        v-model="type"
                        :items="types"
                        item-text="VALUE"
                        item-value="ID"
                        label="Продукция"
                        name="type"
              ></v-select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="dilers_block">
      <div class="cell items ">
        <div class="point" v-for="dealer in dealersListData" v-if="submitStatus !== 'BACKEND_ERROR'">
          <div class="name"><a href="#" v-html="dealer.NAME"></a></div>
          <div class="desc">
            <p v-if="dealer.PHONE" v-for="phone in dealer.PHONE">Тел.: <a :href="'tel:'+phone">{{phone}}</a></p>
            <p v-if="dealer.EMAIL">E-mail: <a :href="'mailto:'+dealer.EMAIL">{{dealer.EMAIL}}</a></p>
            <p v-if="dealer.SITE">Сайт: <a :href="dealer.SITE">{{dealer.SITE}}</a></p>
            <p v-if="dealer.ADDRESS">Адрес: <a :href="dealer.SITE">{{dealer.ADDRESS}}</a></p>
          </div>
        </div>
      </div>
      <div class="cell map" id="map" v-if="submitStatus !== 'BACKEND_ERROR'">
        <yandex-map
            ref="map"
            :coords="[65.478532, 94.637173]"
            zoom="3"
            style="width: 100%; height: 600px;"
        >
          <ymap-marker
              v-for="n in markersNew"
              :key="n.id"
              :marker-id="n.id"
              marker-type="placemark"
              :coords="n.coord"
              :balloon="{ body: n.text }"
          ></ymap-marker>
        </yandex-map>
      </div>
      <div v-else>
        {{ noDealersMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import 'babel-polyfill';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';

export default {
  components: { yandexMap, ymapMarker },
  props: ['filters', 'dealersList','settings','types'],
  data() {
    return {
      markers: [],
      coords: [54, 39],
      country: '',
      region: null,
      type: null,
      countryKey: null,
      dealersListData: [],
      resultData: null,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,

      noDealersMessage: 'Данные корректируются. Просьба позвонить по номеру 8-800-600-98-20.'
    }
  },
  computed: {
    regions: function () {
      let countries = this.filters;
      let regions = [];
      if(countries.length){
        for (let i = 0; i < countries.length; i++) {
          if(countries[i].ID === this.country){
            return countries[i].regions;
          }
        }
      }
      return regions;
    },
    markersNew: function () {
      let markers = [];
      for (let i = 0; i < this.dealersListData.length; i++) {
        let el = {coord: [this.dealersListData[i].LAT, this.dealersListData[i].LON], text: this.dealersListData[i].ADDRESS}
        markers.push(el);
      }
      // console.log(markers);
      return markers.map((n, i) => ({ ...n, id: i + 1 }));
    }
  },
  methods: {
    regionAndTypeReset(){
      this.region = null;
      this.typeReset();
    },
    typeReset(){
      this.type = null;
    },
    async onSubmit() {
      let formData = new FormData();
      formData.append('region', this.region);
      formData.append('type', this.type);

      let v = this;
      try {
        const result = await BX.ajax.runComponentAction('prmtr:dealers', 'filter', {
          mode: 'class',
          data: formData
        });
        if (result.status === 'success') {
          v.dealersListData = result.data;
          v.isSuccess = true;
          v.submitStatus = null;
        }
      } catch (e) {
        this.isSuccess = false;
        v.submitStatus = 'BACKEND_ERROR';
        v.backend_error = e.errors[1].message;
      }
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls__input {
  display: none !important;
}

.check_text.checked:after{
  opacity:1;
}
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>