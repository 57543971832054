import '../css';
import '../js';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
// import VueLazyload from 'vue-lazyload'

//forms
import auth from "./vueComponents/forms/auth.vue";
import reg from "./vueComponents/forms/reg.vue";
import deviceReg from "./vueComponents/forms/deviceReg.vue";
import deviceCheck from "./vueComponents/forms/deviceCheck.vue";
import profileEdit from "./vueComponents/forms/profileEdit.vue";
import passRestore from "./vueComponents/forms/passRestore.vue";
import techDocs from "./vueComponents/techDocs.vue";
import dealers from "./vueComponents/dealers.vue";
//forms
Vue.component('vue-auth',auth);
Vue.component('vue-reg',reg);
Vue.component('vue-device-reg',deviceReg);
Vue.component('vue-device-check',deviceCheck);
Vue.component('vue-profile-edit',profileEdit);
Vue.component('vue-pass-restore',passRestore);
Vue.component('vue-tech-docs',techDocs);
Vue.component('vue-dealers',dealers);

// Vue.use(VueLazyload);
Vue.use(Vuelidate);
Vue.use(VModal);

new Vue({
    vuetify,
}).$mount('#vue-main');