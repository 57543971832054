<template>
  <div class="cont">
    <v-form ref="form" action="#" class="form" @submit.prevent="onSubmit">
      <div class="line_flex flex">

        <div class="line_form">
          <v-text-field
              v-model="serial"
              :rules="rules"
              label="Серийный номер оборудования *"
              hide-details="auto"
          ></v-text-field>
        </div>

        <div class="line_form" style="margin-top:10px;">
          <div class="register__submit">
            <button type="submit" class="register__btn active">Проверить
            </button>
          </div>
        </div>
      </div>
    </v-form>
    <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>
    <div class="register__header" v-if="isSuccess">
      <h1 class="register__title">Результаты проверки оборудования</h1>
      <div class="register__text">
        <p>По введенному Вами серийному номеру обнаружены следующие данные:</p>
      </div>
    </div>
    <div v-if="isSuccess" class="item_find">
      <div class="item__text">
        <p>
          <b>Наименование изделия:</b> {{ this.resultData.NAME }}
        </p>
      </div>

      <div class="item__text">
        <p>
          <b>Дата установки:</b> {{ this.resultData.PROPS.INSTALLATION_DATE.VALUE }}
        </p>
      </div>
      <div class="item__text">
        <p>
          <b>Наименование организации установщика:</b> {{ this.resultData.PROPS.ORGANIZATION_NAME.VALUE }}
        </p>
      </div>
      <div class="item__text">
        <p>
          <b>Дата гарантийного обслуживания:</b> {{ this.resultData.PROPS.WARRANTY_DATE.VALUE }}
        </p>
      </div>

    </div>
  </div>
</template>
<script>
import 'babel-polyfill';

export default {
  data() {
    return {
      dateMask: 'date',
      agreement: true,
      rules: [
        value => !!value || 'Поле обязательно для заполнения',
        value => (value && value.length >= 3) || 'Минимум 3 символа',
      ],
      resultData: null,
      serial: null,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        this.submitStatus = 'ERROR'
      } else {
        let formData = new FormData();
        formData.append('serial', this.serial);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:device_check', 'check', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
            v.resultData = result.data[0];
            // this.$refs.form.reset()
            this.isSuccess = true;
          }
        } catch (e) {
          this.isSuccess = false;
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[1].message;
        }
      }
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls__input {
  display: none !important;
}

/*.check_text.checked:after{
  opacity:1;
}*/
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>