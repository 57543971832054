<template>
  <div class="profile">
    <div class="box_form">
      <form action="#" class="form" @submit.prevent="onSubmit" v-if="submitStatus !== 'OK'">

        <div class="line_form" :class="{ 'form-group--error': $v.password.$error }">
          <div class="label">НОВЫЙ ПАРОЛЬ</div>
          <input v-model="$v.password.$model" type="password" name="password" class="input" placeholder="">
          <div class="error" v-if="!$v.password.minLength">Пароль должен быть не менее
            {{ $v.password.$params.minLength.min }} символов длиной
          </div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.cPassword.$error }">
          <div class="label">ПОДТВЕРЖДЕНИЕ НОВОГО ПАРОЛЯ</div>
          <input v-model="$v.cPassword.$model" type="password" name="password" class="input" placeholder="">
          <div class="error" v-if="!$v.cPassword.sameAsPassword">Пароли должны совпадать</div>
          <div class="error" v-if="!$v.password.minLength">Пароль должен быть не менее
            {{ $v.password.$params.minLength.min }} символов длиной
          </div>
        </div>

        <div class="submit center margin-top">
          <button type="submit" class="submit_btn">Сохранить</button>
        </div>
      </form>
      <p class="typo__p" v-if="submitStatus === 'OK'">Данные успешно изменены</p>
      <p class="typo__p error-message" v-if="submitStatus === 'ERROR'">Пожалуйста, заполните все поля формы
        корректно</p>
      <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>
    </div>
  </div>
</template>
<script>
import MaskedInput from "vue-masked-input";
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';
import 'babel-polyfill';

export default {
  data() {
    return {
      password: '',
      cPassword: '',
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  props: ['userData'],
  validations: {
    password: {
      minLength: minLength(6),
    },
    cPassword: {
      minLength: minLength(6),
      sameAsPassword: sameAs('password')
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let formData = new FormData();
        // formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('cPassword', this.cPassword);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:profile_edit', 'edit', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
            setTimeout(function (){
              window.location = '/profile_edit/';
            },2000);
          }
        } catch (e) {
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[1].message;

        }
      }
    },
    goToProfile(){
      window.location = '/profile_edit/';
    }
  },
  components:{
    MaskedInput
  }
}
</script>
<style scoped>
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.form-group__message, .error {
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  margin: 7px 0 10px 0;
}

.grecaptcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grecaptcha-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

select{
  font-size: 16px;
  padding: 10px;
  border: none;
}
</style>