<template>
  <div class="cont">
    <div class="register__header">
      <h1 class="register__title">Техническая документация</h1>
      <div class="register__text"></div>
    </div>

    <div class="filter_form_block">
      <form id="filters-form">
        <div class="accordion" v-for="filter in filters" :key="filter.name">
          <div class="item active">
            <div class="title"><span class="open_btn">{{ filter.name }}</span></div>
            <div class="data" style="display: block;">
              <div class="checkbox" v-for="filterParam in filter.params">
                <label class="label_check">
                  <input @change="onSubmit" type="checkbox" class="filter_checkbox" :name="filter.code+'[]'"
                         :value="filterParam.VALUE">
                  <span class="check_text">{{ filterParam.VALUE }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div v-if="submitStatus === null" class="filter_content" v-for="device in devicesData">
      <div class="block_name">{{ device.NAME }}</div>
      <div class="block_img">
        <img :src="device.PREVIEW_PICTURE"></div>
      <div class="items_wrap ">
        <div class="items" v-for="(docsCategory,docsCategoriesName) in device.NEW_DOCS">
          <div class="item_title">{{ docsCategoriesName }}</div>
          <div class="item" v-for="doc in docsCategory">
            <div class="news-img">
              <svg>
                <use xlink:href="#pdf"></use>
              </svg>
            </div>
            <div class="news">
              <a :href="doc.PROPS.FILE.SRC" class="link_name">{{ doc.NAME }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="submitStatus === 'BACKEND_ERROR'">
      {{ backend_error }}
    </div>
    <div v-if="!formDirty">
      {{defaultMessage}}
    </div>
  </div>
</template>
<script>
import 'babel-polyfill';

export default {
  props: ['filters', 'devices'],
  data() {
    return {
      devicesData: null,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      defaultMessage: 'Выберите хотя бы 1 тип изделий',
      formDirty: false
    }
  },
  // mounted() {
  //   this.devicesData = this.devices;
  // },
  methods: {
    async onSubmit() {
      let f = document.forms["filters-form"];
      let formData = new FormData(f);
      this.formDirty = true;
      let v = this;
      try {
        const result = await BX.ajax.runComponentAction('prmtr:tech_docs', 'filter', {
          mode: 'class',
          data: formData
        });
        if (result.status === 'success') {
          v.devicesData = result.data;
          v.isSuccess = true;
          v.submitStatus = null;
        }
      } catch (e) {
        this.isSuccess = false;
        v.submitStatus = 'BACKEND_ERROR';
        v.backend_error = e.errors[1].message;
      }
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls__input {
  display: none !important;
}

/*.check_text.checked:after{
  opacity:1;
}*/
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>