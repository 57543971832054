import { render, staticRenderFns } from "./passRestore.vue?vue&type=template&id=584880fe&scoped=true&"
import script from "./passRestore.vue?vue&type=script&lang=js&"
export * from "./passRestore.vue?vue&type=script&lang=js&"
import style0 from "./passRestore.vue?vue&type=style&index=0&id=584880fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584880fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nkarpovich/Sites/prmtr.local/local/templates/pramotronic_lk/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('584880fe')) {
      api.createRecord('584880fe', component.options)
    } else {
      api.reload('584880fe', component.options)
    }
    module.hot.accept("./passRestore.vue?vue&type=template&id=584880fe&scoped=true&", function () {
      api.rerender('584880fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vueComponents/forms/passRestore.vue"
export default component.exports