<template>
  <div class="box_form">
    <form action="#" class="register__form" @submit.prevent="onSubmit" v-if="submitStatus !== 'OK'">
      <fieldset class="register__group">
        <div class="line_form" :class="{ 'form-group--error': $v.email.$error }">
          <input v-model="$v.email.$model" name="email" type="text" class="register__field" placeholder="Логин">
          <div class="error" v-if="!$v.email.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.password.$error }">
          <input v-model="$v.password.$model" type="password" name="password" class="register__field" placeholder="Пароль">
          <div class="error" v-if="!$v.password.required">{{ requiredMessage }}</div>
        </div>
<!--        <div class="checkbox">
          <label class="label_check">
            <input type="checkbox" name="remember" v-model="this.remember" checked>
            <span class="check_text">Запомнить меня на этом компьютере</span>
          </label>
        </div>-->
      </fieldset>
      <div class="register__submit">
        <input class="register__btn active" type="submit" name="submit" value="Войти">
        <input class="register__btn" type="button" value="Зарегистрироваться" @click.prevent="goToRegistration">
      </div>
      <a class="register__forgetpass" href="/pass_restore/">Забыли пароль?</a>
    </form>
    <p class="typo__p" v-if="submitStatus === 'OK'">Авторизация произошла успешно, перенаправление на главную страницу...</p>
    <p class="typo__p error-message" v-if="submitStatus === 'ERROR'">Пожалуйста, заполните все поля формы корректно</p>
    <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>
  </div>
</template>
<script>
import {required, email} from 'vuelidate/lib/validators';
import 'babel-polyfill';

export default {
  data() {
    return {
      email: null,
      password: null,
      remember: true,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  validations: {
    password: {
      required
    },
    email: {
      required
    }
  },
  methods: {
    goToRegistration(){
      window.location = '/reg/';
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = null;
        let formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('remember', this.remember);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:auth', 'auth', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
            setTimeout(function () {
              window.location = '/';
            }, 2000);
          }
        } catch (e) {
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[1].message;
        }
      }
    }
  }
}
</script>
<style scoped>
.box_form{
  max-width:400px;
}
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.form-group__message, .error {
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  margin: 7px 0 10px 0;
}

.grecaptcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grecaptcha-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}
.line_form {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>