<template>
  <div class="sect_subscribe">
    <v-form ref="form" action="#" class="form" @submit.prevent="onSubmit">
      <div class="line_flex flex">
        <div class="line_form">
          <div class="select_wrap">
            <v-select
                v-model="type"
                :rules="rules"
                :items="types"
                item-text="name"
                item-value="code"
                label="Тип оборудования *"
            ></v-select>
          </div>
        </div>

        <div class="line_form">
          <v-text-field
              v-model="companyName"
              :rules="rules"
              label="Название организации установщика *"
              hide-details="auto"
          ></v-text-field>
        </div>

        <div class="line_form">
          <v-text-field
              v-model="model"
              :rules="rules"
              label="Марка транспортного средства *"
              hide-details="auto"
          ></v-text-field>
        </div>
        <div class="line_form">
          <v-text-field
              v-model="email"
              label="E-mail *"
              :rules="rules"
              hide-details="auto"
          ></v-text-field>
        </div>

        <div class="line_form">
          <v-text-field
              v-model="vin"
              :rules="rules"
              label="VIN-номер транспортного средства *"
              hide-details="auto"
          ></v-text-field>
        </div>
        <div class="line_form">
          <v-text-field
              v-model="phone"
              :rules="rules"
              label="Контактный номер *"
              hide-details="auto"
          ></v-text-field>
        </div>

        <div class="line_form">
          <v-text-field
              v-model="serial"
              :rules="rules"
              label="Серийный номер оборудования *"
              hide-details="auto"
          ></v-text-field>
        </div>

        <div class="line_form">
          <v-text-field
              type="date"
              v-model="installationDate"
              label="Дата установки *"
              :rules="rules"
              hide-details="auto"
          ></v-text-field>
        </div>
        <div class="line_form">
          <label>* -Обязательные поля</label>
          <div class="checkbox">
            <label class="label_check">
              <v-checkbox
                  v-model="agreement"
                  :rules="[v => !!v || 'Обязательное поле']"
                  required
              ></v-checkbox>
              <span class="check_text" :class="{checked:agreement}">С <a href="/confidentiality/" target="_blank">политикой обработки персональных данных</a> согласен и ознакомлен</span>
            </label>

          </div>
        </div>
        <div class="line_form" style="margin-top:10px;">
          <div class="register__submit">
            <button type="submit" class="register__btn active">Зарегистрировать
            </button>
            <button type="button" class="register__btn" @click="goToCheckPage" data-content="#check_item">
              Проверить оборудование
            </button>
          </div>
        </div>
      </div>
    </v-form>
<!--    <p class="typo__p error-message" v-if="submitStatus === 'ERROR'">Пожалуйста, заполните все поля формы корректно</p>-->
    <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>

    <v-dialog
        v-model="dialog"
        width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2" style="justify-content:center;">
          Оборудование успешно зарегистрировано
        </v-card-title>

        <div class="success" style="margin-bottom: 20px;"></div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import 'babel-polyfill';

export default {
  data() {
    return {
      dateMask: 'date',
      agreement:true,
      rules: [
        value => !!value || 'Поле обязательно для заполнения',
        value => (value && value.length >= 3) || 'Минимум 3 символа',
      ],
      dialog: false,
      types: [
        {name:'Отопитель воздушный', code:'AIR'},
        {name:'Отопитель воздушный AirPro', code: 'AIR_PRO'}
      ],
      type: null,
      model: null,
      vin: null,
      serial: null,
      installationDate: null,
      companyName: null,
      email: null,
      phone: null,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  methods: {
    goToCheckPage() {
      window.location = '/device_check/';
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        this.submitStatus = 'ERROR'
      } else {
        let formData = new FormData();
        formData.append('type', this.type);
        formData.append('model', this.model);
        formData.append('vin', this.vin);
        formData.append('serial', this.serial);
        formData.append('installationDate', this.installationDate);
        formData.append('companyName', this.companyName);
        formData.append('email', this.email);
        formData.append('phone', this.phone);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:device_reg', 'reg', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
            this.$refs.form.reset()
            this.dialog = true;
          }
        } catch (e) {
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[0].message;
        }
      }
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls__input{
  display:none !important;
}
.check_text.checked:after{
  opacity:1;
}
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.form-group__message, .error {
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  margin: 7px 0 10px 0;
}

.grecaptcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grecaptcha-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>