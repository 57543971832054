var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cont" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "filter_form_block" }, [
        _c(
          "form",
          { attrs: { id: "filters-form" } },
          _vm._l(_vm.filters, function(filter) {
            return _c("div", { key: filter.name, staticClass: "accordion" }, [
              _c("div", { staticClass: "item active" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", { staticClass: "open_btn" }, [
                    _vm._v(_vm._s(filter.name))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "data", staticStyle: { display: "block" } },
                  _vm._l(filter.params, function(filterParam) {
                    return _c("div", { staticClass: "checkbox" }, [
                      _c("label", { staticClass: "label_check" }, [
                        _c("input", {
                          staticClass: "filter_checkbox",
                          attrs: { type: "checkbox", name: filter.code + "[]" },
                          domProps: { value: filterParam.VALUE },
                          on: { change: _vm.onSubmit }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check_text" }, [
                          _vm._v(_vm._s(filterParam.VALUE))
                        ])
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm._l(_vm.devicesData, function(device) {
        return _vm.submitStatus === null
          ? _c("div", { staticClass: "filter_content" }, [
              _c("div", { staticClass: "block_name" }, [
                _vm._v(_vm._s(device.NAME))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "block_img" }, [
                _c("img", { attrs: { src: device.PREVIEW_PICTURE } })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "items_wrap " },
                _vm._l(device.NEW_DOCS, function(
                  docsCategory,
                  docsCategoriesName
                ) {
                  return _c(
                    "div",
                    { staticClass: "items" },
                    [
                      _c("div", { staticClass: "item_title" }, [
                        _vm._v(_vm._s(docsCategoriesName))
                      ]),
                      _vm._v(" "),
                      _vm._l(docsCategory, function(doc) {
                        return _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "news-img" }, [
                            _c("svg", [
                              _c("use", { attrs: { "xlink:href": "#pdf" } })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "news" }, [
                            _c(
                              "a",
                              {
                                staticClass: "link_name",
                                attrs: { href: doc.PROPS.FILE.SRC }
                              },
                              [_vm._v(_vm._s(doc.NAME))]
                            )
                          ])
                        ])
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.submitStatus === "BACKEND_ERROR"
        ? _c("div", [_vm._v("\n    " + _vm._s(_vm.backend_error) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.formDirty
        ? _c("div", [_vm._v("\n    " + _vm._s(_vm.defaultMessage) + "\n  ")])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "register__header" }, [
      _c("h1", { staticClass: "register__title" }, [
        _vm._v("Техническая документация")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "register__text" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }