<template>
  <div class="sect_subscribe">
    <form action="#" class="form" @submit.prevent="onSubmit" v-if="submitStatus !== 'OK'">
      <div class="line_flex flex">
        <div class="line_form" :class="{ 'form-group--error': $v.lastName.$error }">
          <div class="label">Фамилия*</div>
          <input v-model="$v.lastName.$model" name="lastName" type="text" class="input">
          <div class="error" v-if="!$v.lastName.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.name.$error }">
          <div class="label">Имя*</div>
          <input v-model="$v.name.$model" name="name" type="text" class="input">
          <div class="error" v-if="!$v.name.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.secondName.$error }">
          <div class="label">Отчество*</div>
          <input v-model="$v.secondName.$model" name="secondName" type="text" class="input">
          <div class="error" v-if="!$v.secondName.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.companyName.$error }">
          <div class="label">Наименование компании*</div>
          <input v-model="$v.companyName.$model" type="text" name="companyName" class="input" placeholder="">
          <div class="error" v-if="!$v.companyName.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.address.$error }">
          <div class="label">Юридический адрес*</div>
          <input v-model="$v.address.$model" type="text" name="password" class="input" placeholder="">
          <div class="error" v-if="!$v.address.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.location.$error }">
          <div class="label">Местоположение (страна, регион, город)*</div>
          <input v-model="$v.location.$model" type="text" name="location" class="input" placeholder="">
          <div class="error" v-if="!$v.location.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.location.$error }">
          <div class="label">Телефон*</div>
          <input v-model="$v.phone.$model" type="text" name="phone" class="input" placeholder="">
          <div class="error" v-if="!$v.phone.required">{{ requiredMessage }}</div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.email.$error }">
          <label>E-mail*</label>
          <input v-model="$v.email.$model" name="email" type="text" class="input">
          <div class="error" v-if="!$v.email.required">{{ requiredMessage }}</div>
          <div class="error" v-if="!$v.email.email">Введите валидный e-mail</div>
        </div>


        <div class="line_form" :class="{ 'form-group--error': $v.password.$error }">
          <div class="label">Пароль*</div>
          <input v-model="$v.password.$model" type="password" name="password" class="input" placeholder="">
          <div class="error" v-if="!$v.password.required">{{ requiredMessage }}</div>
          <div class="error" v-if="!$v.password.minLength">Пароль должен быть не менее
            {{ $v.password.$params.minLength.min }} символов длиной
          </div>
        </div>

        <div class="line_form" :class="{ 'form-group--error': $v.cPassword.$error }">
          <div class="label">Подтверждение пароля*</div>
          <input v-model="$v.cPassword.$model" type="password" name="cPassword" class="input" placeholder="">
          <div class="error" v-if="!$v.cPassword.required">{{ requiredMessage }}</div>
          <div class="error" v-if="!$v.cPassword.sameAsPassword">Пароли должны совпадать</div>
          <div class="error" v-if="!$v.password.minLength">Пароль должен быть не менее
            {{ $v.password.$params.minLength.min }} символов длиной
          </div>
        </div>
        <div class="line_form">
          <label>* -Обязательные поля</label>
          <div class="checkbox">
            <label class="label_check">
              <v-checkbox
                  v-model="agreement"
                  :rules="[v => !!v || 'Обязательное поле']"
                  required
              ></v-checkbox>
              <span class="check_text" :class="{checked:agreement}">С <a href="/confidentiality/" target="_blank">политикой обработки персональных данных</a> согласен и ознакомлен</span>
            </label>

          </div>
        </div>
        <div class="line_form">
          * Обязательные поля
        </div>

        <div class="submit center margin-top">
          <button type="submit" class="register__btn active">Зарегистрироваться</button>
        </div>
      </div>
    </form>
    <p class="typo__p" v-if="submitStatus === 'OK'">Регистрация прошла успешно,
      для подтверждения регистрации перейдите по ссылке в письме, которое было
      отправлено на введенный вами электронный адрес. Проверьте папку спам, письмо может попасть туда.</p>
    <p class="typo__p error-message" v-if="submitStatus === 'ERROR'">Пожалуйста, заполните все поля формы корректно</p>
    <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>
  </div>
</template>
<script>
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators';
import 'babel-polyfill';

export default {
  data() {
    return {
      agreement:true,
      email: null,
      password: null,
      cPassword: null,
      name: '',
      lastName: '',
      secondName: '',
      companyName: '',
      address: '',
      location: '',
      phone: '',
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    cPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password')
    },
    phone: {
      required
    },
    companyName: {
      required
    },
    name: {
      required
    },
    lastName: {
      required
    },
    secondName: {
      required
    },
    location: {
      required
    },
    address: {
      required
    },
    email: {
      required,
      email
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('cPassword', this.cPassword);
        formData.append('name', this.name);
        formData.append('lastName', this.lastName);
        formData.append('secondName', this.secondName);
        formData.append('companyName', this.companyName);
        formData.append('phone', this.phone);
        formData.append('location', this.location);
        formData.append('address', this.address);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:reg', 'reg', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
          }
        } catch (e) {
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[0].message;
        }
      }
    }
  }
}
</script>
<style scoped>
.check_text.checked:after{
  opacity:1;
}
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.form-group__message, .error {
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  margin: 7px 0 10px 0;
}

.grecaptcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grecaptcha-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>