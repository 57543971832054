<template>
  <div class="box_form">
    <div class="desc"> <br></div>
    <form action="#" class="form" @submit.prevent="onSubmit" v-if="submitStatus !== 'OK'">
      <div class="note center small">Укажите адрес электронной почты, который вы использовали при регистрации и мы вышлем
        инструкцию по восстановлению.</div>
      <div class="line_form" :class="{ 'form-group--error': $v.email.$error }">
        <div class="label">E-MAIL</div>
        <input v-model="$v.email.$model" name="email" type="text" class="input">
        <div class="error" v-if="!$v.email.required">{{ requiredMessage }}</div>
      </div>

      <div class="submit center margin-top">
        <button type="submit" class="submit_btn">Восстановить пароль</button>
      </div>
    </form>
    <p class="typo__p" v-if="submitStatus === 'OK'">Ссылка на восстановление пароля отправлена на ваш e-mail, указанный при регистрации</p>
    <p class="typo__p error-message" v-if="submitStatus === 'BACKEND_ERROR'">{{ this.backend_error }}</p>
  </div>
</template>
<script>
import {required} from 'vuelidate/lib/validators';
import 'babel-polyfill';
export default {
  data() {
    return {
      email: null,
      submitStatus: null,
      isSuccess: null,
      error: null,
      backend_error: null,
      requiredMessage: 'Поле обязательно для заполнения'
    }
  },
  validations: {
    email: {
      required
    }
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = null;
        let formData = new FormData();
        formData.append('email', this.email);

        let v = this;
        try {
          const result = await BX.ajax.runComponentAction('prmtr:pass_restore', 'restore', {
            mode: 'class',
            data: formData
          });
          if (result.status === 'success') {
            v.submitStatus = 'OK';
          }
        }  catch (e) {
          v.submitStatus = 'BACKEND_ERROR';
          v.backend_error = e.errors[0].message;
        }
      }
    }
  }
}
</script>
<style scoped>
.error-message {
  color: #f57f6c;
}

.form-group--error + .form-group__message, .form-group--error + .error, .form-group--error .error {
  display: block;
  color: #f57f6c;
}

.form-group--error input, .form-group--error textarea, .form-group--error input:focus, .form-group--error input:hover,
.form-group--error select {
  border-color: #f79483;
}

.form-group--error .form__label, .form-group--error .form__label--inline {
  color: #f04124;
}

.form-group__message, .error {
  display: none;
  font-size: 0.75rem;
  line-height: 1;
  margin: 7px 0 10px 0;
}

.grecaptcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grecaptcha-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.typo__p {
  text-align: center;
  padding-top: 10px;
}

.form-group--alert,
.form-group--error {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>