var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sect_subscribe" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { action: "#" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "line_flex flex" }, [
            _c("div", { staticClass: "line_form" }, [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c("v-select", {
                    attrs: {
                      rules: _vm.rules,
                      items: _vm.types,
                      "item-text": "name",
                      "item-value": "code",
                      label: "Тип оборудования *"
                    },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules,
                    label: "Название организации установщика *",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.companyName,
                    callback: function($$v) {
                      _vm.companyName = $$v
                    },
                    expression: "companyName"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules,
                    label: "Марка транспортного средства *",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    label: "E-mail *",
                    rules: _vm.rules,
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules,
                    label: "VIN-номер транспортного средства *",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.vin,
                    callback: function($$v) {
                      _vm.vin = $$v
                    },
                    expression: "vin"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules,
                    label: "Контактный номер *",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.phone,
                    callback: function($$v) {
                      _vm.phone = $$v
                    },
                    expression: "phone"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.rules,
                    label: "Серийный номер оборудования *",
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.serial,
                    callback: function($$v) {
                      _vm.serial = $$v
                    },
                    expression: "serial"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "line_form" },
              [
                _c("v-text-field", {
                  attrs: {
                    type: "date",
                    label: "Дата установки *",
                    rules: _vm.rules,
                    "hide-details": "auto"
                  },
                  model: {
                    value: _vm.installationDate,
                    callback: function($$v) {
                      _vm.installationDate = $$v
                    },
                    expression: "installationDate"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "line_form" }, [
              _c("label", [_vm._v("* -Обязательные поля")]),
              _vm._v(" "),
              _c("div", { staticClass: "checkbox" }, [
                _c(
                  "label",
                  { staticClass: "label_check" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        rules: [
                          function(v) {
                            return !!v || "Обязательное поле"
                          }
                        ],
                        required: ""
                      },
                      model: {
                        value: _vm.agreement,
                        callback: function($$v) {
                          _vm.agreement = $$v
                        },
                        expression: "agreement"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "check_text",
                        class: { checked: _vm.agreement }
                      },
                      [
                        _vm._v("С "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/confidentiality/",
                              target: "_blank"
                            }
                          },
                          [_vm._v("политикой обработки персональных данных")]
                        ),
                        _vm._v(" согласен и ознакомлен")
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "line_form",
                staticStyle: { "margin-top": "10px" }
              },
              [
                _c("div", { staticClass: "register__submit" }, [
                  _c(
                    "button",
                    {
                      staticClass: "register__btn active",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Зарегистрировать\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "register__btn",
                      attrs: { type: "button", "data-content": "#check_item" },
                      on: { click: _vm.goToCheckPage }
                    },
                    [
                      _vm._v(
                        "\n              Проверить оборудование\n            "
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _vm.submitStatus === "BACKEND_ERROR"
        ? _c("p", { staticClass: "typo__p error-message" }, [
            _vm._v(_vm._s(this.backend_error))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "text-h5 grey lighten-2",
                  staticStyle: { "justify-content": "center" }
                },
                [
                  _vm._v(
                    "\n          Оборудование успешно зарегистрировано\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "success",
                staticStyle: { "margin-bottom": "20px" }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("\n            Закрыть\n          ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }