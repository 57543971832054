var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box_form" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.submitStatus !== "OK"
      ? _c(
          "form",
          {
            staticClass: "form",
            attrs: { action: "#" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "note center small" }, [
              _vm._v(
                "Укажите адрес электронной почты, который вы использовали при регистрации и мы вышлем\n      инструкцию по восстановлению."
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "line_form",
                class: { "form-group--error": _vm.$v.email.$error }
              },
              [
                _c("div", { staticClass: "label" }, [_vm._v("E-MAIL")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.email.$model,
                      expression: "$v.email.$model"
                    }
                  ],
                  staticClass: "input",
                  attrs: { name: "email", type: "text" },
                  domProps: { value: _vm.$v.email.$model },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.$v.email, "$model", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                !_vm.$v.email.required
                  ? _c("div", { staticClass: "error" }, [
                      _vm._v(_vm._s(_vm.requiredMessage))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm._m(1)
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "OK"
      ? _c("p", { staticClass: "typo__p" }, [
          _vm._v(
            "Ссылка на восстановление пароля отправлена на ваш e-mail, указанный при регистрации"
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "BACKEND_ERROR"
      ? _c("p", { staticClass: "typo__p error-message" }, [
          _vm._v(_vm._s(this.backend_error))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "desc" }, [_c("br")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submit center margin-top" }, [
      _c("button", { staticClass: "submit_btn", attrs: { type: "submit" } }, [
        _vm._v("Восстановить пароль")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }