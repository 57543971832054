var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sect_subscribe" }, [
    _vm.submitStatus !== "OK"
      ? _c(
          "form",
          {
            staticClass: "form",
            attrs: { action: "#" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "line_flex flex" }, [
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.lastName.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("Фамилия*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.lastName.$model,
                        expression: "$v.lastName.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { name: "lastName", type: "text" },
                    domProps: { value: _vm.$v.lastName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.lastName, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.lastName.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.name.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("Имя*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.name.$model,
                        expression: "$v.name.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { name: "name", type: "text" },
                    domProps: { value: _vm.$v.name.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.name, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.name.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.secondName.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("Отчество*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.secondName.$model,
                        expression: "$v.secondName.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { name: "secondName", type: "text" },
                    domProps: { value: _vm.$v.secondName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.secondName,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.secondName.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.companyName.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Наименование компании*")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.companyName.$model,
                        expression: "$v.companyName.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "text",
                      name: "companyName",
                      placeholder: ""
                    },
                    domProps: { value: _vm.$v.companyName.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.companyName,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.companyName.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.address.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Юридический адрес*")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.address.$model,
                        expression: "$v.address.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { type: "text", name: "password", placeholder: "" },
                    domProps: { value: _vm.$v.address.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.address, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.address.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.location.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Местоположение (страна, регион, город)*")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.location.$model,
                        expression: "$v.location.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { type: "text", name: "location", placeholder: "" },
                    domProps: { value: _vm.$v.location.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.location, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.location.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.location.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("Телефон*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.phone.$model,
                        expression: "$v.phone.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { type: "text", name: "phone", placeholder: "" },
                    domProps: { value: _vm.$v.phone.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.phone, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.phone.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.email.$error }
                },
                [
                  _c("label", [_vm._v("E-mail*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.email.$model,
                        expression: "$v.email.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: { name: "email", type: "text" },
                    domProps: { value: _vm.$v.email.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.email, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.email.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.email.email
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v("Введите валидный e-mail")
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.password.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("Пароль*")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: ""
                    },
                    domProps: { value: _vm.$v.password.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.password, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.password.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.password.minLength
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(
                          "Пароль должен быть не менее\n          " +
                            _vm._s(_vm.$v.password.$params.minLength.min) +
                            " символов длиной\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.cPassword.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("Подтверждение пароля*")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.cPassword.$model,
                        expression: "$v.cPassword.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "password",
                      name: "cPassword",
                      placeholder: ""
                    },
                    domProps: { value: _vm.$v.cPassword.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.cPassword,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.cPassword.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.cPassword.sameAsPassword
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v("Пароли должны совпадать")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.password.minLength
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(
                          "Пароль должен быть не менее\n          " +
                            _vm._s(_vm.$v.password.$params.minLength.min) +
                            " символов длиной\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "line_form" }, [
                _c("label", [_vm._v("* -Обязательные поля")]),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox" }, [
                  _c(
                    "label",
                    { staticClass: "label_check" },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          rules: [
                            function(v) {
                              return !!v || "Обязательное поле"
                            }
                          ],
                          required: ""
                        },
                        model: {
                          value: _vm.agreement,
                          callback: function($$v) {
                            _vm.agreement = $$v
                          },
                          expression: "agreement"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "check_text",
                          class: { checked: _vm.agreement }
                        },
                        [
                          _vm._v("С "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "/confidentiality/",
                                target: "_blank"
                              }
                            },
                            [_vm._v("политикой обработки персональных данных")]
                          ),
                          _vm._v(" согласен и ознакомлен")
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "line_form" }, [
                _vm._v("\n        * Обязательные поля\n      ")
              ]),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "OK"
      ? _c("p", { staticClass: "typo__p" }, [
          _vm._v(
            "Регистрация прошла успешно,\n    для подтверждения регистрации перейдите по ссылке в письме, которое было\n    отправлено на введенный вами электронный адрес. Проверьте папку спам, письмо может попасть туда."
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "ERROR"
      ? _c("p", { staticClass: "typo__p error-message" }, [
          _vm._v("Пожалуйста, заполните все поля формы корректно")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "BACKEND_ERROR"
      ? _c("p", { staticClass: "typo__p error-message" }, [
          _vm._v(_vm._s(this.backend_error))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submit center margin-top" }, [
      _c(
        "button",
        { staticClass: "register__btn active", attrs: { type: "submit" } },
        [_vm._v("Зарегистрироваться")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }