var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box_form" }, [
    _vm.submitStatus !== "OK"
      ? _c(
          "form",
          {
            staticClass: "register__form",
            attrs: { action: "#" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c("fieldset", { staticClass: "register__group" }, [
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.email.$error }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.email.$model,
                        expression: "$v.email.$model"
                      }
                    ],
                    staticClass: "register__field",
                    attrs: {
                      name: "email",
                      type: "text",
                      placeholder: "Логин"
                    },
                    domProps: { value: _vm.$v.email.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.email, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.email.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.password.$error }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass: "register__field",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: "Пароль"
                    },
                    domProps: { value: _vm.$v.password.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.password, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.password.required
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(_vm._s(_vm.requiredMessage))
                      ])
                    : _vm._e()
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "register__submit" }, [
              _c("input", {
                staticClass: "register__btn active",
                attrs: { type: "submit", name: "submit", value: "Войти" }
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "register__btn",
                attrs: { type: "button", value: "Зарегистрироваться" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goToRegistration.apply(null, arguments)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "register__forgetpass",
                attrs: { href: "/pass_restore/" }
              },
              [_vm._v("Забыли пароль?")]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "OK"
      ? _c("p", { staticClass: "typo__p" }, [
          _vm._v(
            "Авторизация произошла успешно, перенаправление на главную страницу..."
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "ERROR"
      ? _c("p", { staticClass: "typo__p error-message" }, [
          _vm._v("Пожалуйста, заполните все поля формы корректно")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.submitStatus === "BACKEND_ERROR"
      ? _c("p", { staticClass: "typo__p error-message" }, [
          _vm._v(_vm._s(this.backend_error))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }