var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "profile" }, [
    _c("div", { staticClass: "box_form" }, [
      _vm.submitStatus !== "OK"
        ? _c(
            "form",
            {
              staticClass: "form",
              attrs: { action: "#" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.password.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [_vm._v("НОВЫЙ ПАРОЛЬ")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.password.$model,
                        expression: "$v.password.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: ""
                    },
                    domProps: { value: _vm.$v.password.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.$v.password, "$model", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.password.minLength
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(
                          "Пароль должен быть не менее\n          " +
                            _vm._s(_vm.$v.password.$params.minLength.min) +
                            " символов длиной\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "line_form",
                  class: { "form-group--error": _vm.$v.cPassword.$error }
                },
                [
                  _c("div", { staticClass: "label" }, [
                    _vm._v("ПОДТВЕРЖДЕНИЕ НОВОГО ПАРОЛЯ")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.cPassword.$model,
                        expression: "$v.cPassword.$model"
                      }
                    ],
                    staticClass: "input",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: ""
                    },
                    domProps: { value: _vm.$v.cPassword.$model },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.$v.cPassword,
                          "$model",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.cPassword.sameAsPassword
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v("Пароли должны совпадать")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.password.minLength
                    ? _c("div", { staticClass: "error" }, [
                        _vm._v(
                          "Пароль должен быть не менее\n          " +
                            _vm._s(_vm.$v.password.$params.minLength.min) +
                            " символов длиной\n        "
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm._m(0)
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.submitStatus === "OK"
        ? _c("p", { staticClass: "typo__p" }, [
            _vm._v("Данные успешно изменены")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.submitStatus === "ERROR"
        ? _c("p", { staticClass: "typo__p error-message" }, [
            _vm._v("Пожалуйста, заполните все поля формы\n      корректно")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.submitStatus === "BACKEND_ERROR"
        ? _c("p", { staticClass: "typo__p error-message" }, [
            _vm._v(_vm._s(this.backend_error))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submit center margin-top" }, [
      _c("button", { staticClass: "submit_btn", attrs: { type: "submit" } }, [
        _vm._v("Сохранить")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }